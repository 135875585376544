<template>
    <Dropdown
        id="tabs"
        name="tabs"
        :choices="mobileChoices"
        :model-value="mobileSelection"
        @update:model-value="onMobileCategoryClick($event)"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ArticleCategoryType from '~/types/ArticleCategoryType';

const props = defineProps<{
    categories: ArticleCategoryType[];
    currentCategory: string;
}>();

const mobileChoices = computed<[]>(() => {
    const choices: any[] = props.categories.map(
        (choice: ArticleCategoryType) => {
            return {
                value: choice.slug,
                label: choice.label,
            };
        },
    );
    return choices;
});

const mobileSelection = computed<string>(() => {
    const selectedCategory = props.categories.filter(
        (category: ArticleCategoryType) => {
            return category.id === props.currentCategory;
        },
    )[0];
    if (selectedCategory) {
        return selectedCategory.slug;
    }
});

const onMobileCategoryClick = (value: string) => {
    window.location.href = '/' + value + '#blog-category-menu';
};
</script>
